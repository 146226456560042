<template>
  <base-section id="seminare">
    <base-section-heading title="Kurse und Seminare" />

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :id="feature.title"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            {{ feature.text }}
          </base-avatar-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-carousel hide-delimiters>
          <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
            :src="item.src"
            reverse-transition="fade-transition"
            transition="fade-transition"
          />
        </v-carousel>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionSeminare',

    data: () => ({
      items: [
        {
          src: './assets/img/Kursbilder/kurs11.jpeg',
        },
        {
          src: './assets/img/Kursbilder/kurs12.jpeg',
        },
        {
          src: './assets/img/Kursbilder/kurs13.jpeg',
        },
      ],
      features: [
        {
          color: 'primary',
          dark: true,
          icon: 'mdi-dots-circle',
          title: 'Kompaktausbildung Astrologie & Psychologie',
          text: 'Mehr zur Kompaktausbildung erfährst du hier:',
        },
        {
          color: 'primary',
          dark: true,
          icon: 'mdi-circle-double',
          title: 'Aktuelle Seminare für Astrologen',
          text: 'Kurse und Seminare die ich gebe, findest du hier:',
        },
      ],
    }),
    mounted () {
      const param = new URLSearchParams(window.location.search)
      if (param.has('focus')) {
        this.$vuetify.goTo('#' + param.get('focus'))
        window.history.pushState({}, document.title, '/')
      }
    },
  }
</script>

<style scoped>
.v-carousel {
  height: 250px !important;
  max-width: 400px !important;
}
</style>
<style>
.v-image__image--cover {
  background-size: contain !important;
  background-position: 0% 0% !important;
}
</style>
